import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`Some of the most striking traces of the work Orson Welles did to prepare “The Heart of Darkness” are the numerous drawings he made in the margins and on the verso side of the pages of the first typewritten draft of the script. Welles made drawings that correspond to many parts of the planned film, from the proposed, non-diegetic introduction intended to explain the subjective camera technique to Kurtz’s speech presented in the visual and aural style of the Nuremberg Rallies. Welles’s drawings provide some of the best indications of what his first first-person singular approach to feature filmmaking might have looked like. Some also show vectors of movement that suggested we might adapt these drawings to produce fully-realized animations. Our student team of animators, voice-actors, sound designers, and composers reimagined six sequences from Welles’s “The Heart of Darkness” based on these drawings, along with other archival documents.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/sketch3.png","alt":null}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Animations","key":"animations","parentKey":"heart-of-darkness","sortOrder":5};

  